<template>
  <pro-menu-layout>
    <div class="q-pa-md">
      <div class="col text-right">
        <q-btn
          no-caps
          class="col q-ml-sm"
          padding="xs md"
          color="primary"
          :label="getRes('System.Button.ExportAttendanceRecord')"
          rounded
          outline
          @click="downloadFile()"
          style="width: 300px"
        />
      </div>
      <pro-deck
        :title="getRes('Document.TendererList')"
        :cards="registeredList"
        v-slot="{ card: registeredTenderer }"
      >
        <pro-deck-card
          :title="registeredTenderer.cmpyNameEng"
          :actions="
            registeredTenderer.tenderBriefingId <= 0
              ? []
              : deckCardList(
                  registeredTenderer.tendererId,
                  registeredTenderer.tenderBriefingId,
                  registeredTenderer.id <= 0
                )
          "
          :disable="registeredTenderer.removed"
          expandable
        >
          <div class="row">
            <div class="col-auto">
              <pro-key-value-table
                :rows="tendererTableRows"
                :data="registeredTenderer"
              />
            </div>
          </div>
        </pro-deck-card>
      </pro-deck>
    </div>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProKeyValueTable from "@/components/PROSmart/ProKeyValueTable";

const viewFormRouteName = "DocumentViewTenderBriefingForTendererByProcurer";
const workflowCode = "S_T_RegForTendBrAtt";

export default {
  components: { ProMenuLayout, ProDeck, ProDeckCard, ProKeyValueTable },
  data() {
    return {
      tenderStatus: 0,
      tendererList: [],
      registeredList: [],
      registeredPage: {},
      fileId: "",
    };
  },
  computed: {
    tendererTableRows() {
      return [
        {
          label: this.getRes("Form.Field.LastSubmission"),
          field: "submissionDate",
        },
      ];
    },
  },
  methods: {
    formatDate(date) {
      return this.$proSmart.common.getFormattedDate(new Date(date + "+0800"));
    },
    async downloadFile() {
      await this.$proSmart.documentUi
        .exportAttendanceRecordFileId(this, this.$route.params.id, workflowCode)
        .then((fileObj) => {
          this.fileId = fileObj.attendanceResultFileId;
        });

      this.$proSmart.download.downloadFile(
        this,
        this.$route.params.id,
        this.fileId
      );
    },
    deckCardList(tendererId, tenderBrId) {
      return [
        {
          label: this.getRes("System.Button.View"),
          to: {
            name: viewFormRouteName,
            params: {
              mode: "View",
              code: workflowCode,
              tendererId: tendererId,
              tenderBriefingId: tenderBrId,
              tenderId: this.$route.params.id,
            },
          },
        },
      ];
    },
  },
  async created() {
    const regList = await this.$proSmart.documentUi.getTenderBriefingRegistration(
      this,
      this.$route.params.id
    );
    console.log("REG LIST:", regList);

    await this.$proSmart.documentUi
      .getTenderBriefingRegistration(this, this.$route.params.id)
      .then((registeredList) => {
        this.registeredList = registeredList.map(
          (registeredTenderer, index) => ({
            key: index,
            tenderBriefingId: registeredTenderer.id,
            tendererId: registeredTenderer.tendererId,
            cmpyNameEng: registeredTenderer.companyName,
            submissionDate:
              registeredTenderer.id > 0
                ? this.formatDate(registeredTenderer.submissionDate)
                : "not replied yet",
          })
        );
      });
    console.log("REG LIST2:", this.registeredList);

    this.$proSmart.tender
      .getAllTendererList(this, this.$route.params.id, true)
      .then((tendererList) => {
        this.tendererList = tendererList.map((tenderer, index) => ({
          key: index,
          supplierCode: tenderer.supplierId,
          cmpyNameEng: tenderer.name,
          emailAddress: tenderer.emailAddress.address,
          ccPerson: tenderer.ccList.map((cc) => cc.address).join("; "),
          removed: !tenderer.active,
          source: tenderer.source,
          accessCode: tenderer.accessCode,
        }));
      });
  },
};
</script>
